import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import ai from "../assets/img/ai.png";
import monies from "../assets/img/monies.png";
import flex from "../assets/img/flex.png";
import weather from "../assets/img/weather.png";
import violation from "../assets/img/violation.png"


function Projects() {
  return (
    <Container fluid className="bg-gray-900 py-10">
      <Container>
      <h2 id="projects"
                      style={{
                        textAlign: "center",
                        marginBottom: "100px",
                        marginTop: "40px",
                      }}
                    >
                      RECENT PROJECTS
                    </h2>
        <Row className="flex flex-wrap -mx-4">
          <Col md={4} className="mb-4 px-4">
            <ProjectCard
              imgPath={flex}
              isBlog={false}
              title="Flex Fusion"
              techstack="React, Nodejs, Express, Sequelize"
              description="Developed a fitness application with integrated API’s, Sequelize backend databases and a live user base deployed through AWS. "
              link="https://github.com/androrezkalla/flex-fusion"
            />
          </Col>
          <Col md={4} className="mb-4 px-4">
            <ProjectCard
              imgPath={violation}
              isBlog={false}
              title="NYC Violations"
              techstack="Javascript, React, NYC OpenData API's"
              description="The project utilizes NYC Open Data to fetch and display DOB, FDNY and ECB violations. Users can easily navigate between violation types and search by BIN numbers."
              link="https://github.com/androrezkalla/NYC-Violations"
            />
          </Col>
          <Col md={4} className="mb-4 px-4">
            <ProjectCard
              imgPath={monies}
              isBlog={false}
              title="Better Budget"
              techstack="React, JavaScript, Tailwindcss"
              description="A web application that allows users to track their income and expenses. It provides a simple and intuitive interface for managing transactions and visualizing financial data."
              link="https://github.com/androrezkalla/Budget-Tracker"
            />
          </Col>
          <h2 class="resgap">//..h....//</h2>
        </Row>
      </Container>
    </Container>
    
  );
}

export default Projects;
