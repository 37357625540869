import { Container, Row } from "react-bootstrap";



export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
        </Row>
      </Container>
    </footer>
  )
}
