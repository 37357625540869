import { Container, Row, Col } from "react-bootstrap";
import Resumecontent from "./ResumeContent";

export function Resume() {
  return (
    <Container fluid className="resume-section" id="resume">
      <Container>
        <Row style={{ justifyContent: "center", position: "flex", maxWidth: "300px", }}>
        </Row>
        <h2
                      style={{
                        padding: "1.3rem",
                        textAlign: "center",
                      }}
                    >
                      EXPERIENCE
                    </h2>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Work</h3>
            <Resumecontent
              company="RBC Capital Markets"
              title="Technology Operations Summer Analyst"
              date="May 2024 - Present"
              content={[
                "Facilitated the onboarding process for over 450+ incoming employees which included windows imaging, MDM profile‬ setups, VPN configurations, business app licensing and MECM/SCCM application deployments on company issued laptops‬ ",
                "Developed a web application to manage assets during the build process, focusing on Active Directory Automations, Registry Key detection, asset readiness state, batch management, inventory tracking, and a backend API to scrape asset details using React, Javascript, Express, PostgreSQL, Selenium, Python, and Flask",
                "Streamlined the entire onboarding process by creating a dashboard capable of Active Directory Automation, Registry Key validation and other asset management features which provided a potential cost avoidance of $43,543 a year per onboarding session and leading to a sharp 78% increase in workflow efficiency by combining user centric and asset centric approaches into a single view."
              ]}
            />
            <Resumecontent
              company="City University Of New York - FPCM"
              title="Violations System Intern"
              date="Nov 2023 - Present"
              content={[
                "Assisted in managing the city-wide building violation system for all 25 CUNY campuses and DASNY properties across New‬ ‭ York City, using software tools and data management techniques to organize, streamline and visualize large amounts of‬ ‭ data, handling up to 3,000 building violations monthly‬ ‭ ",
                "Developed software using NYC OpenData APIs to fetch various building violation information from different government‬ ‭ agencies including New York Fire Department(FDNY), Department of Buildings, and Environmental Control Board‬ ‭",
              ]}
            />
            <Resumecontent
              company="CUNY Research Foundation"
              title="Software Engineer Intern"
              date="Feb 2023 - May 2023"
              content={[
                "Developed a full-stack software application using React, Node.js, and TypeScript in a technical team of 7 people, with occasional rotation between front-end and back-end development",
                "Improved application runtime by 28% through optimizing old file structures and ensuring code efficiency throughout the project",
                "Conducted weekly QA testing to ensure stability and functionality, resulting in a higher quality product and user experience",
                "Utilized Figma to design UI components and implemented them into the platform, contributing to an appealing user interface"
              ]}
            />
            <Resumecontent
              company="Teladoc Health"
              title="Software Engineer Intern"
              date="Jan 2023 - Feb 2023"
              content={[
                "Identified and automated a manual data checking process previously performed by engineers effectively reducing the time and resources required for data checking and validation by 10% per week",
                "Utilized comprehensive web technologies such as TypeScript, ReactJS, Node.js  to design a compelling front-end",
                "Documented code and wrote technical specifications to ensure future maintainability and accessibility of the project",
                "Utilized Git as a version control system, enabling efficient management of code changes within the development team",
              ]}
            />
          </Col>
          <Col md={6} className="resume-right">
          <h3 className="resume-title">Education</h3>
            <Resumecontent
              company="City University of New York, 
               College of Staten Island"
              date="Anctipiated Graduation: May 2024"
              title="B.S. in Computer Science"
              content={["Pursuing a Bachelor's Degree in Computer Science from the University of New York"]}
            />
             <h3 className="resume-title">NYC Tech Talent Pipelien</h3>
            <Resumecontent
              title=""
              content={[
                "Completed a 10 week full-stack web development bootcamp focused on the best development practices in an agile environment and mastering technical skills including JavaScript, Node.JS, Express.JS, React, PostgreSQL, and AWS.",
                "Developed a full-stack fitness web application with a team of 4 engineers utilizing the PERN stack where enabled users have the ability to create workout plans, track their progress, analyze nutrition, and take a quiz to gain access to a custom tailored workout routine",
                "Leveraged technologies including Node.JS, Express.JS, Sequelize, and session-based authentication to craft a seamless and intuitive user experience, while integrating the OpenAI API for custom workout plans",
                "Effectively launched the application on AWS, accommodating a live user base, and harnessed a seamlessly integrated PostgreSQL backend database to ensure enhanced reliability and optimal performance"
              ]}
            />

            <h3 className="resume-title">Courses</h3>
            <Resumecontent
              title=""
              content={[
                "CSC 326 - Data Structures",
                "CSC 228 - Discrete Mathematical Structures ",
                "CSC 382 - Analysis of Algorithms",
                "CSC 436 - Modern Web Development",
                "CSC 430 - Software Engineering",
                "CSC 446 - Computer Architecture",
                "CSC 330 - Object-Oriented Software Design ",
                "CSC 220 - Computers, Networking & Security",
                "CSC 347 - Computer Circuits Laboratory",
                "CSC 346 - Switching & Automata Theory ",
                "CSC 211 - Intermediate Programming",
              ]}
            />
          
        
            <h3 className="resume-title">Clubs</h3>
            <Resumecontent
              company="Hack-A-Project Club"
              title="City University of New York - CSI"
              date="2020 - Present"
              content={[
              ]}
            />
            <Resumecontent
              company="FRC Robotics Team 333"
              title="John Dewey High School"
              date="2016 - 2020"
              content={[
              ]}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
        </Row>
      </Container>
    </Container>
  );
}