import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";
import "./project-cards.css";

function ProjectCards(props) {
  const imageStyle = {
    height: "200px", // Set a fixed height for the images
    width: "100%", // Automatically adjust the width to maintain aspect ratio
    objectFit: "cover" // Maintain aspect ratio and cover the container
  };

  return (
    <Card className="project-card-view">
      <Card.Img
        variant="top"
        src={props.imgPath}
        alt="card-img"
        style={imageStyle}
      />
      <Card.Body className="card-body">
        <div>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text className="tech-stack">{props.techstack}</Card.Text>
          <Card.Text>{props.description}</Card.Text>
        </div>
        <Button
          variant="primary"
          href={props.link}
          target="_blank"
          className="view-button"
        >
          <BiLinkExternal /> &nbsp;
          {props.isBlog ? "View Blog" : "View Project"}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
