import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Aboutme = () => {
  return (
    <section className="contact" id="aboutme">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Hi! here's a few things about me...</h2>
                <p>My name is Andro Rezkalla, I am a first generation Computer Science student at the City University of New York. I was born in Egypt, grew up in Saudi Arabia and currently live in New York City!</p>
                <p>Some of my interests in computer science include:</p> 
                    <p>* Web Development</p> 
                    <p>* ML/AI Models </p> 
                    <p>* Process Automations</p>
                <p> My interests in computer science were sparked after I participated in my local Robotics Club
                   at John Dewey High School. Since then, I gained full-stack development expierence at multiple companies
                   and started exploring my passions for web development and data science through projects like this one!
                </p>

                
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}


/* 
<form action="https://drive.google.com/file/d/1RBKq__70k-zWIJ87r-_mKxevKeG03rPa/view?usp=sharing" method="get" target="_blank">
                <input type="submit" value="Resume" />
</form>
*/